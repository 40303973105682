
<!-- Page Wrapper -->
<div id="wrapper">

  <!-- Sidebar -->
  <!-- <ul *ngIf="currentUserValue()" class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ocultar-print" [class.toggled]="toggled" id="accordionSidebar"> -->

    <!-- Sidebar - Brand -->
    <!-- <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/usos/asignar-ruta">
      <div class="sidebar-brand-icon">
        <img class="img-profile img-fluid" src="assets/img/logo-mas.png">
      </div>
      <div class="sidebar-brand-text mx-3">Dashboard</div>
    </a> -->

    <!-- Divider -->
    <!-- <hr class="sidebar-divider my-0">



    <!-- Divider -->
    <!-- <hr class="sidebar-divider"> -->

    <!-- Heading -->
    <!-- <div class="sidebar-heading">
      Planillas
    </div> -->

    <!-- Nav Item - Pages Collapse Menu -->
    <!-- <li class="nav-item" *ngIf="currentUser.rol === 'admin' || currentUser.rol === 'supervisor' || currentUser.rol === 'tdp'">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#UsosContainer" aria-expanded="true"
         aria-controls="UsosContainer">
        <i class="fas fa-route"></i> <span> Rutas </span>
      </a>
      <div id="UsosContainer" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Seteo de Ruta:</h6>
          <a class="collapse-item" routerLink="/usos/asignar-ruta">Buses</a>
          
        </div>
      </div>
    </li> -->


 

 


    <!-- Sidebar Toggler (Sidebar) -->
    <!-- <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle" (click)="toggle()"></button>
    </div> -->

  <!-- </ul> -->
  <!-- End of Sidebar -->

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">

    <!-- Main Content -->
    <div id="content">

      <!-- Topbar -->
      <nav *ngIf="currentUserValue()" class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

        <!-- Sidebar Toggle (Topbar) -->
        <!-- <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" (click)="toggle()">
          <i class="fa fa-bars"></i>
        </button> -->

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">

          <div class="topbar-divider d-none d-sm-block"></div>

          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{currentUser.nombre}} {{currentUser.apellido}}</span>
              <i class="fas fa-user"></i>
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Cerrar sesión
              </a>
            </div>
          </li>

        </ul>

      </nav>
      <!-- End of Topbar -->

      <router-outlet></router-outlet>

    </div>
    <!-- End of Main Content -->

  </div>
  <!-- End of Content Wrapper -->

</div>
<!-- End of Page Wrapper -->

<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>

<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">¿Seguro que desea salir?</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
        <button class="btn btn-primary" (click)="auth.logout()" data-dismiss="modal">Salir</button>
      </div>
    </div>
  </div>
</div>

<!-- Loading -->
<div class="my-overlay" *ngIf="storage.loading">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>

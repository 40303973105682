import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import localePY from '@angular/common/locales/es-PY';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {AuthModule} from './auth/auth.module';
import {HttpClientModule} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';





registerLocaleData(localePY, 'es-PY');

function tokenGetter(): string {
  return localStorage.getItem('a_token');
}

@NgModule({
  declarations: [
    AppComponent,
   
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [
          environment.CENTRAL_HOST,
          environment.BUSES_HOST
        ],
        blacklistedRoutes: [
          `${environment.PROTOCOL}${environment.CENTRAL_HOST}/token/`,
          `${environment.PROTOCOL}${environment.CENTRAL_HOST}/token/refresh/`,
        ],
      },
    }),
    AuthModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

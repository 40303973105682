import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from './models';
import {Router} from '@angular/router';
import {StorageService} from '../utils/storage.service';
import {environment} from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private BASE_URL: string = environment.PROTOCOL + environment.CENTRAL_HOST;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public error: any = {};
  public helper = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.renewToken();
  }

  public async renewToken(): Promise<any> {
    const refresh = localStorage.getItem('r_token');
    if (!refresh) {
      this.logout();
    }
    const rExpired = this.helper.isTokenExpired(refresh);
    if (rExpired) {
      this.logout();
    }
    const access = localStorage.getItem('a_token');
    if (!access) {
      this.logout();
    }
    const aExpired = this.helper.isTokenExpired(access);
    if (aExpired) {
      const token = await this.http.post<any>(this.BASE_URL + '/token/refresh/', {refresh}).toPromise().catch(
        err => {
          if (err.error instanceof Error) {
            console.error('An error occurred:', err.error.message);
          } else {
            console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
            if (err.status === 401) {
              this.logout();
            }
          }
          return null;
        }
      );
      if (token) {
        localStorage.setItem('a_token', token.access);
      }
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public login(username: string, password: string): void {
    this.storage.loading = true;
    this.http.post<any>(this.BASE_URL + '/token/', {username, password}).subscribe(
      tokens => {
        const aToken = tokens.access;
        localStorage.setItem('a_token', aToken);
        localStorage.setItem('r_token', tokens.refresh);
        const user = this.helper.decodeToken(tokens.access);
        this.currentUserSubject.next(user);
        if(['supervisor'].some(role => user.rol === role)){
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.router.navigate(['/usos/asignar-ruta']);
          this.error = '';
          this.storage.setFechaDesde(new Date().toJSON().slice(0, 10));
          this.storage.setFechaHasta(new Date().toJSON().slice(0, 10));
          this.storage.loading = false;
          
        }else{

          alert("No tiene los permisos necesesarios");
          this.logout;
          this.storage.loading = false;
        }
       
         
      },
      err => {
        console.error(err);
        this.error = err.error;
        this.storage.loading = false;
      },
      () => this.storage.loading = false
    );
  }

  logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('a_token');
    localStorage.removeItem('r_token');
    this.currentUserSubject.next(null);
    this.router.navigate(['login']);
  }
}



import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'usos/asignar-ruta',
    pathMatch: 'full'
  },
  // {
  //   path: 'central',
  //   loadChildren: () => import('./dash/dash.module').then(m => m.DashModule)
  // },
  // {
  //   path: 'cds',
  //   loadChildren: () => import('./cds/cds.module').then(m => m.CdsModule)
  // },
  {
    path: 'usos',
    loadChildren: () => import('./usos/usos.module').then(m => m.UsosModule)
  },
  // {
  //   path: 'estado-cuenta',
  //   loadChildren: () => import('./estado-cuenta/estado-cuenta.module').then(m => m.EstadoCuentaModule)
  // },
  // {
  //   path: 'estado-canal',
  //   loadChildren: () => import('./estado-canal/estado-canal.module').then(m => m.EstadoCanalModule)
  // },
  // {
  //   path: 'estado-interoperabilidad',
  //   loadChildren: () => import('./estado-interoperabilidad/estado-interoperabilidad.module').then(m => m.EstadoInteroperabilidadModule)
  // },
  // {
  //   path: 'detalles',
  //   loadChildren: () => import('./detalles/detalles.module').then(m => m.DetallesModule)
  // },
  // {
  //   path: 'monitoreo',
  //   loadChildren: () => import('./monitoreo/monitoreo.module').then(m => m.MonitoreoModule)
  // },
  // {
  //   path: 'global',
  //   loadChildren: () => import('./global/global.module').then(m => m.GlobalModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
